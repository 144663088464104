<template>
  <div id="page-adjusting-machine">
    <!-- 筛选区块 -->
    <div class="filter">
      <div class="filter-item">
        <Select v-model="listParam.searchType" style="width: 120px">
          <Option v-for="item in searchTypeList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.searchText"
          @keyup.enter.native="getTableListData"
          style="width: 200px"
        />
      </div>

      <div class="filter-item">
        <span style="margin-right: 10px">调机结束时间</span>
        <DatePicker
          type="date"
          placeholder="请选择日期"
          v-model="listParam.endDate"
          @on-change="handleDateChange"
        />
      </div>
    </div>

    <!-- 自定义的表格操作栏 -->
    <div class="tableToolBar">
      <div class="item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="item" @click="exportTableData">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>
    <!-- 表格 -->
    <Table :border="true" :loading="loading" :columns="tableColumns" :data="tableData" />
    <!-- 分页 -->
    <Page
      class-name="page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
import { searchTypeList } from "./constant";
import { fetchAdjustingMachineList } from "./api";

export default {
  name: "MassProduce",
  data() {
    return {
      searchTypeList,

      // 首页表格列表的请求参数
      listParam: {
        searchType: 1, // 1:机台号,2:调机人员
        searchText: "",

        endDate: "", // 调机结束时间（止）如"2021-07-16"

        pageNum: 1,
        pageSize: 10,
      },

      // 首页表格配置
      loading: false,
      tableColumns: [
        {
          title: "序号",
          type: "index",
          minWidth: 80,
        },
        {
          title: "机台号",
          key: "deviceNum",
          minWidth: 120,
        },
        {
          title: "调机人员",
          key: "userName",
          minWidth: 140,
        },
        {
          title: "调机开始时间",
          key: "startDate",
          minWidth: 180,
        },
        {
          title: "调机结束时间",
          key: "endDate",
          minWidth: 180,
        },
      ],

      tableData: [],

      listDataTotal: 0, // 分页器数据总条数
    };
  },
  created() {
    this.getTableListData();
  },
  methods: {
    getTableListData() {
      this.loading = true;
      fetchAdjustingMachineList(this.listParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { list } = res.data.body;

            this.tableData = list.list;
            this.listDataTotal = list.total;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    handleDateChange(formatDate, DateObj) {
      this.listParam.endDate = formatDate;
      this.getTableListData();
    },

    // 导出表格
    exportTableData() {
      const { pageNum, pageSize, ...paramObj } = this.listParam;
      const data = {
        flag: paramObj.searchType,
        message: paramObj.searchText,
        endDate: paramObj.endDate,
      };
      const dataStr = this.qs.stringify(data);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/order/ProductExecuteController/changeMachineInfoExport?${dataStr}`;
    },
  },
};
</script>

<style lang="scss" scoped>
// 筛选区块
.filter {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .filter-item {
    display: inline-block;
    margin: 20px 40px 20px 0;
    white-space: nowrap;
  }
}

// 表格操作栏
.tableToolBar {
  display: flex;
  font-size: $--fontsize-small;
  margin-top: -20px;
  margin-bottom: 20px;
  .item {
    color: $--color-blue-standard;
    margin-right: 30px;
    cursor: pointer;
  }
}

// 表格下面的分页器部分
.page {
  margin-top: 10px;
  text-align: center;
}
</style>

<style lang="scss">
</style>
