// 搜索类型的 Select 下拉框列表
const searchTypeList = [
  {
    value: 1,
    label: "机台号"
  },
  {
    value: 2,
    label: "调机人员"
  }
];

export { searchTypeList };
