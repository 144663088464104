import axios from "axios";

/**
 * 获取调机首页列表——分页列表
 */
export const fetchAdjustingMachineList = ({ pageNum, pageSize, ...paramObj }) => {
  return axios({
    url: "/dtsum/zongtong/order/ProductExecuteController/changeMachineInfo",
    method: "GET",
    params: {
      pageNum,
      pageSize,
      param: {
        flag: paramObj.searchType,
        message: paramObj.searchText,
        endDate: paramObj.endDate
      }
    }
  });
};
